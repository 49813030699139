import React, { useEffect, useRef } from "react"
import { Box } from "theme-ui"

export default function EventsScript() {
  const scriptContainerRef = useRef(null)

  useEffect(() => {
    const scriptId = "tripleseat-script"
    if (document.getElementById(scriptId)) {
      return
    }

    const script = document.createElement("script")
    script.id = scriptId
    script.src =
      "https://api.tripleseat.com/v1/leads/ts_script.js?lead_form_id=33254&public_key=992f8e431cbb3847c9a8130c6c0b322bdc1540b6"
    script.async = true

    // Event listeners for script loading and errors
    script.addEventListener("load", () =>
      console.log("Tripleseat script loaded successfully.")
    )
    script.addEventListener("error", e =>
      console.error("Failed to load Tripleseat script:", e.message)
    )

    scriptContainerRef.current.appendChild(script)

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script)
      }
    }
  }, [])

  return (
    <Box className="script-container">
      <div ref={scriptContainerRef}></div>
      <a id="tripleseat_link" href="https://www.tripleseat.com">
        Private Event Software powered by Tripleseat
      </a>
    </Box>
  )
}
