import React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"
import MultipleLinksSelector from "../Links/MultipleLinksSelector"

const PrivateEventsContentContainer = ({
  title = "",
  text = [],
  ctaLinks,
  image = [],
  setLightbox,
  subtitle,
}) => {
  return (
    <Section>
      <Container>
        <StyledFlex>
          <div className="half">
            <Title>{title}</Title>
            <Paragraph>{subtitle}</Paragraph>
            {/* {text.map(txt => (
              <p
                style={{ fontWeight: "normal" }}
                dangerouslySetInnerHTML={{ __html: txt }}
              ></p>
            ))} */}
            <div
              style={{ fontWeight: "normal" }}
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
            <MultipleLinksSelector links={ctaLinks} />
          </div>
          <Flex className="half" style={{}}>
            {image.map(img => (
              <div
                className="thirds"
                onClick={() =>
                  setLightbox({
                    isOpen: true,
                    mainSrc: img,
                  })
                }
              >
                <img src={img} alt="title" />
              </div>
            ))}
          </Flex>
        </StyledFlex>
      </Container>
    </Section>
  )
}

export default PrivateEventsContentContainer

const Section = styled.section`
  padding: 4rem 1.5rem;
  background: white;
`

const Container = styled.div`
  max-width: ${({ width }) => (width ? width : "900px")};
  margin: auto;
`

const Title = styled.h3`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ theme }) => theme.primary};
  font-size: 2rem;
`

const Paragraph = styled.p`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  font-weight: normal;
  margin: ${({ margin }) => margin};
  margin-bottom: 1rem;
`

const Btn = styled.button`
  padding: 0.75rem 1.5rem;
  background: ${({ theme }) => theme.primary};
  border-radius: 3px;
  box-shadow: none;
  border: none;
  color: white;
  width: 100%;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.primary};
  font-weight: bold;
  font-size: 1.25rem;
  &:hover {
    background: transparent;
    color: black !important;
    transition: all 0.3s;
    a {
      color: black;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
  a {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;

    &:hover {
      color: black !important;
    }
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  > .half {
    width: 100%;
    padding: 1rem;
    @media (min-width: 768px) {
      //   width: 50%;
    }
  }

  > .thirds {
    padding: 12px;
    img {
      @media (min-width: 576px) {
        // width: 50%;
      }
      @media (min-width: 768px) {
        // width: 33%;
      }
    }
  }
`

const StyledFlex = styled(Flex)`
  @media (min-width: 900px) {
    flex-direction: column;
    align-items: center;

    .thirds {
      width: 33%;
      cursor: pointer;
    }
  }
`
