import React from "react"
import styled from "styled-components"
import { Element } from "react-scroll"

import Pergola4 from "../../assets/private-events/pergola-4.jpg"

const StyledForm = () => {
  return (
    <Element name="form">
      <Wrapper>
        <Title textAlign="center" color="white">
          Request a private event
        </Title>
        <form
          name="private-events-form"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="private-events-form" />
          <div>
            <label htmlFor="name">
              <p>Name</p>
              <input type="text" name="name" />
            </label>
          </div>
          <div>
            <label htmlFor="email">
              <p>Email</p>
              <input type="email" name="email" />
            </label>
          </div>
          <div>
            <label htmlFor="phone">
              <p>Phone</p>
              <input type="text" name="phone" />
            </label>
          </div>
          <div>
            <label htmlFor="peopleEstimate">
              <p>People Estimate</p>
              <input type="text" name="peopleEstimate" />
            </label>
          </div>
          <div>
            <label htmlFor="message">
              <p>Message</p>
              <textarea type="text" name="message" />
            </label>
          </div>
          <div style={{ textAlign: "center" }}>
            <Btn type="submit">Submit</Btn>
          </div>
        </form>
      </Wrapper>
    </Element>
  )
}

export default StyledForm

const Wrapper = styled.div`
  /* background-attachment: fixed; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${Pergola4});
  background-size: cover;
  padding: 8rem 0rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  form {
    max-width: 600px;
    margin: auto;
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 1.5rem;
    display: block;
    background: rgba(0, 0, 0, 0.25);
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 2px solid white;
    color: white;
    outline: none;
  }

  textarea {
    min-height: 100px;
    padding: 16px;
  }

  input {
    padding: 1rem 0.25rem;
  }

  label {
    color: white;
  }

  form {
    width: 100%;
  }
`

const Btn = styled.button`
  padding: 0.75rem 1.5rem;
  background: ${({ theme }) => theme.primary};
  border-radius: 3px;
  box-shadow: none;
  border: none;
  color: white;
  width: 100%;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.primary};
  font-weight: bold;
  font-size: 1.25rem;
  &:hover {
    background: transparent;
    color: black !important;
    transition: all 0.3s;
    a {
      color: black;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
  a {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;

    &:hover {
      color: black !important;
    }
  }
`

const Title = styled.h3`
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ theme, color }) => (color ? color : theme.primary)};
  font-size: 1.5rem;
`
