import React from "react"
import styled from "styled-components"

const PrivateEventsBanner = ({ backgroundColor = "#fff", children }) => {
  return <Banner backgroundColor={backgroundColor}>{children}</Banner>
}

export default PrivateEventsBanner

const Banner = styled.div`
  padding: 3.25rem 1.5rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
